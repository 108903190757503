import { useEffect, useMemo } from 'react';
import { NavLink, Route, Routes, useParams, useRoutes } from 'react-router-dom';
import useFetchAllMortgages from '../../../../state-management/slices/mortgage/hooks/useFetchAllMortgages';
import { useAppSelector } from '../../../../state-management/hooks';
import { Draft, Folders } from '../../../images';
import { IAllLoansTableData } from '../../../../Sections/Originator/Pages/Quality/types';
import routeStrings from '../../../../Routing/routeStrings';
import { RouteUtils } from '../../../../Sections/Originator/Pages/Quality/RouteUtils';
import { ChecklistReviewFilter } from 'Sections/Originator/Pages/Quality/CloseChecklist/components/ChecklistTablePanel/ChecklistTableHeader';
import { selectAllStatusMortgages } from '../../../../state-management/slices/quality/selectors';
import { QualityBadgeNumber } from '../../../../Sections/Originator/Pages/Quality/components/QualityBadgeCell';
import useGetAllDocumentCategorization from '../../../../state-management/slices/quality/hooks/useGetAllDocumentCategorization';
import useGetAllLoansPath from '../../../../Sections/Originator/Pages/Quality/hooks/useGetAllLoansPath';
import RuleManagerButton from '../../../components/RuleManager/RuleManagerButton';
import useFetchOneMortgage from 'state-management/slices/mortgage/hooks/useFetchOneMortage';

const { quality } = routeStrings.originator;

const SidebarNavigationQuality = () => {
  const allLoans = useAppSelector(selectAllStatusMortgages);

  const routesWithMortgage = useMemo(
    () =>
      [RouteUtils.homepageUrl, RouteUtils.closeChecklistUrl, `${RouteUtils.documentCategorizationReviewPureUrl}/*`].map(
        (path) => ({ path, element: <AllLoansLink allLoans={allLoans} withMortgage /> })
      ),
    [allLoans]
  );
  const allLinkWithMortgage = useRoutes([
    { path: '*', element: <AllLoansLink allLoans={allLoans} withMortgage={false} /> },
    ...routesWithMortgage
  ]);

  return (
    <div className="panel-items">
      {/* All loans link */}
      {allLinkWithMortgage}

      {/* Mortgage data */}
      <Routes>
        <Route path="*" element={null} />
        <Route path={RouteUtils.homepageUrl} element={<QualityMortgagePanel allLoans={allLoans} />} />
        <Route path={`${RouteUtils.closeChecklistUrl}/*`} element={<QualityMortgagePanel allLoans={allLoans} />} />
        <Route
          path={`${RouteUtils.documentCategorizationReviewPureUrl}/*`}
          element={<QualityMortgagePanel allLoans={allLoans} />}
        />
        <Route path={`${RouteUtils.loanQualityAdvisorUrl}/*`} element={<QualityMortgagePanel allLoans={allLoans} />} />
      </Routes>

      <RuleManagerButton className="panel-item" />
    </div>
  );
};

const AllLoansLink = ({
  allLoans,
  withMortgage = false
}: {
  allLoans: IAllLoansTableData[];
  withMortgage?: boolean;
}) => {
  const { pathWithMortgage, pathWithNoMortgage } = useGetAllLoansPath();
  const { isLoading: isMortgagesLoading } = useFetchAllMortgages();
  const { isPending: isDocumentStatusLoading } = useGetAllDocumentCategorization();
  const isAllLoansLoading = isMortgagesLoading || isDocumentStatusLoading;

  const allLoansBadgeValue = useMemo(() => {
    if (isAllLoansLoading) {
      return {
        isLoading: true,
        errors: 0,
        isPending: true
      };
    }

    const isPending = allLoans.every((loan) => loan.closeChecklist.isPending && loan.documentCategorization.isPending);
    // HD-4100 change errors to total loans count
    const errors = allLoans.length;

    return { isLoading: false, errors, isPending };
  }, [allLoans, isAllLoansLoading]);

  const path = withMortgage ? pathWithMortgage : pathWithNoMortgage;

  return (
    <NavLink end to={path} className="panel-item">
      <div className="panel-item-icon">
        <Folders />
      </div>
      All Loans <QualityBadgeNumber value={allLoansBadgeValue} />
    </NavLink>
  );
};

const QualityMortgagePanel = ({ allLoans }: { allLoans: IAllLoansTableData[] }) => {
  const { mortgageId: selectedMortgageId } = useParams();
  const { refetch: fetchMortgage, isLoading } = useFetchOneMortgage(selectedMortgageId as string);

  const selectedMortgage = useMemo(() => {
    if (!selectedMortgageId) {
      return;
    }

    return allLoans.find((loan) => loan.internalId === selectedMortgageId);
  }, [allLoans, selectedMortgageId]);

  useEffect(() => {
    fetchMortgage();
  }, [fetchMortgage]);

  if (!selectedMortgage) {
    return null;
  }

  const { loanNumber, internalId, status, closeChecklist, documentCategorization } = selectedMortgage;

  return (
    <>
      <div className="panel-item selected-loan not-actionable">
        <div className="panel-item-icon">
          <Draft />
        </div>
        {loanNumber}{' '}
        <QualityBadgeNumber
          value={{
            isLoading: closeChecklist.isLoading || documentCategorization.isLoading || isLoading,
            errors: closeChecklist.errors + documentCategorization.errors,
            isPending: closeChecklist.isPending || documentCategorization.isPending
          }}
        />
      </div>

      {/* TODO: [LQA] Restore when LQA is ready */}
      {/* <LQASidebarEntry mortgageId={internalId} /> */}

      <NavLink
        to={RouteUtils.closeChecklistWithParams(internalId, ChecklistReviewFilter.Review)}
        className="panel-item nested"
      >
        Close Checklist <QualityBadgeNumber value={closeChecklist} />
      </NavLink>

      <NavLink
        className="panel-item nested"
        to={`/${quality.base}/${status}/${quality.documentCategorization}/${internalId}`}
      >
        Doc Categorization <QualityBadgeNumber value={documentCategorization} />
      </NavLink>
    </>
  );
};

export default SidebarNavigationQuality;
