import { createSlice, Draft } from '@reduxjs/toolkit';
import { flattenMortgage, flattenMortgages } from '../../helpers/slice.helper';
import { MortgageThunks } from '../../thunks/mortgage.thunks';
import { IMortgageRepository } from 'shared/api/interfaces/IMortgageRepository';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';
import { IMortgageFlat, IMortgageHistory } from 'shared/models/Mortgage';
import { isEqual } from 'lodash';
import {
  AddDeactivatedMortgagesPayload,
  RemoveDeactivatedMortgagePayload,
  FailedToDeactivatePayload,
  SetMortgagesPayload,
  SetMortgageHistoryPayload,
  AppendMortgagePayload
} from './types';

export type MortgageSlice = {
  draftMortgages: IMortgageFlat[];
  openMortgages: IMortgageFlat[];
  pendingMortgages: IMortgageFlat[];
  closedMortgages: IMortgageFlat[];
  soldMortgages: IMortgageFlat[];
  deactivatedMortgages: IMortgageFlat[];
  currentDeactivatedCount: number;
  deactivatedMortgagesQueue: Set<IMortgageFlat['internalId']>;
  failedToDeactivate: IMortgageFlat['internalId'][];
  mortgageHistory: Record<IMortgageFlat['internalId'], IMortgageHistory>;
};

const initialState: MortgageSlice = {
  draftMortgages: [],
  openMortgages: [],
  pendingMortgages: [],
  closedMortgages: [],
  soldMortgages: [],
  deactivatedMortgages: [],
  currentDeactivatedCount: 0,
  deactivatedMortgagesQueue: new Set(),
  failedToDeactivate: [],
  mortgageHistory: {}
};

const mortgageRepository = container.get<IMortgageRepository>(SERVICE_KEYS.MORTGAGE_REPOSITORY);
const mortgageThunks = new MortgageThunks(mortgageRepository);

export const fetchDraftMortgages = mortgageThunks.getDraftMortgages();
export const fetchOpenMortgages = mortgageThunks.getOpenMortgages();
export const fetchPendingMortgages = mortgageThunks.getPendingMortgages();
export const fetchSoldMortgages = mortgageThunks.getSoldMortgages();
export const fetchDeactivatedMortgages = mortgageThunks.getDeactivatedMortgages();
export const addMortgage = mortgageThunks.add();
export const updateMortgage = mortgageThunks.update();
export const openMortgages = mortgageThunks.openMortgages();
export const deleteMortgage = mortgageThunks.delete();
export const transferMortgage = mortgageThunks.transfer();
export const deactivateMortgage = mortgageThunks.deactivateMortgage();
export const deactivateSingleMortgage = mortgageThunks.deactivateSingleMortgage();
export const reactivateMortgage = mortgageThunks.reactivateMortgage();
export const getMortgageCsvById = mortgageThunks.getMortgageCsvById();
export const getMortgageXmlById = mortgageThunks.getMortgageXmlById();
export const getBulkMortgageXml = mortgageThunks.getBulkMortgageXml();
export const getMortgageDocumentsZip = mortgageThunks.getMortgageDocumentsZip();
export const getMortgageDocumentsStatus = mortgageThunks.getMortgageDocumentsStatus();

// prefix underscore to avoid lint rule redeclaring block level var
const _setSoldMortgages = (state: Draft<MortgageSlice>, { payload }: SetMortgagesPayload) => {
  if (!Array.isArray(payload?.data)) return;
  const sold = flattenMortgages(payload.data);

  if (!isEqual(state.soldMortgages, sold)) {
    state.soldMortgages = sold;
    state.closedMortgages = [...sold, ...state.deactivatedMortgages];
  }
};

const _appendToSoldMortgages = (state: Draft<MortgageSlice>, { payload }: AppendMortgagePayload) => {
  if (!payload?.data) return;
  const sold = flattenMortgage(payload.data);

  if (!state.soldMortgages.find((mortgage) => mortgage.internalId === sold.internalId)) {
    state.soldMortgages.push(sold);
    state.closedMortgages = [...state.soldMortgages, ...state.deactivatedMortgages];
  }
};

const _appendToDeactivatedMortgages = (state: Draft<MortgageSlice>, { payload }: AppendMortgagePayload) => {
  if (!payload?.data) return;
  const deactivated = flattenMortgage(payload.data);

  if (!state.deactivatedMortgages.find((mortgage) => mortgage.internalId === deactivated.internalId)) {
    state.deactivatedMortgages.push(deactivated);
    state.closedMortgages = [...state.soldMortgages, deactivated];
  }
};

const _setOpenMortgages = (state: Draft<MortgageSlice>, { payload }: SetMortgagesPayload) => {
  if (!Array.isArray(payload?.data)) return;
  const open = flattenMortgages(payload.data);

  if (!isEqual(state.openMortgages, open)) {
    state.openMortgages = open;
  }
};

// prefix underscore to avoid lint rule redeclaring block level var
const _setPendingMortgages = (state: Draft<MortgageSlice>, { payload }: SetMortgagesPayload) => {
  if (!Array.isArray(payload?.data)) return;
  const pendingMortgages = flattenMortgages(payload.data);

  if (!isEqual(state.pendingMortgages, pendingMortgages)) {
    state.pendingMortgages = pendingMortgages;
  }
};

const _setDraftMortgages = (state: Draft<MortgageSlice>, { payload }: SetMortgagesPayload) => {
  if (!Array.isArray(payload?.data)) return;
  const draft = flattenMortgages(payload.data);

  if (!isEqual(state.draftMortgages, draft)) {
    state.draftMortgages = draft;
  }
};

const _setDeactivatedMortgages = (state: Draft<MortgageSlice>, { payload }: SetMortgagesPayload) => {
  if (!Array.isArray(payload?.data)) return;
  const deactivated = flattenMortgages(payload.data);

  if (!isEqual(state.deactivatedMortgages, deactivated)) {
    state.deactivatedMortgages = deactivated;
    state.closedMortgages = [...deactivated, ...state.soldMortgages];
  }
};

const _setMortgageHistory = (state: Draft<MortgageSlice>, { payload }: SetMortgageHistoryPayload) => {
  if (!payload?.id) return;

  state.mortgageHistory[payload.id] = payload;
};

const mortgageSlice = createSlice({
  name: 'mortgage',
  initialState,
  reducers: {
    addDeactivatedMortgagesToQueue: (state, { payload }: AddDeactivatedMortgagesPayload) => {
      payload.forEach((mortgageInternalId) => {
        state.deactivatedMortgagesQueue = state.deactivatedMortgagesQueue.add(mortgageInternalId);
      });

      state.currentDeactivatedCount += payload.length;
    },
    removeDeactivatedMortgageFromQueue: (state, { payload }: RemoveDeactivatedMortgagePayload) => {
      if (state.deactivatedMortgagesQueue.has(payload)) {
        state.deactivatedMortgagesQueue.delete(payload);
      }
    },
    resetDeactivated: (state) => {
      state.currentDeactivatedCount = 0;
      state.deactivatedMortgagesQueue = new Set();
      state.failedToDeactivate = [];
    },
    addFailedDeactivate: (state, { payload }: FailedToDeactivatePayload) => {
      state.failedToDeactivate.push(payload);
    },
    setSoldMortgages: _setSoldMortgages,
    setOpenMortgages: _setOpenMortgages,
    setPendingMortgages: _setPendingMortgages,
    setDraftMortgages: _setDraftMortgages,
    setMortgageHistory: _setMortgageHistory,
    setDeactivatedMortgages: _setDeactivatedMortgages,
    appendToSoldMortgages: _appendToSoldMortgages,
    appendToDeactivatedMortgages: _appendToDeactivatedMortgages
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDraftMortgages.fulfilled, _setDraftMortgages);

    builder.addCase(fetchOpenMortgages.fulfilled, _setOpenMortgages);

    builder.addCase(fetchPendingMortgages.fulfilled, _setPendingMortgages);

    builder.addCase(fetchSoldMortgages.fulfilled, _setSoldMortgages);

    builder.addCase(fetchDeactivatedMortgages.fulfilled, _setDeactivatedMortgages);

    builder.addCase(updateMortgage.fulfilled, (state, { payload }) => {
      const updatedMortgage = payload.data;

      state.draftMortgages = state.draftMortgages.map((mortgage) => {
        if (mortgage.universalLoanId === updatedMortgage.universalLoanId) mortgage = flattenMortgage(updatedMortgage);
        return mortgage;
      });
    });

    // We want to remove the mortgage from the draft list as soon as is fulfilled
    builder.addCase(openMortgages.fulfilled, (state, { meta }) => {
      state.draftMortgages = state.draftMortgages.filter((mortgage) => !meta.arg.includes(mortgage.internalId));
    });

    builder.addCase(deleteMortgage.fulfilled, (state, { payload }) => {
      const { universalLoanId } = payload.data;

      state.draftMortgages = state.draftMortgages.filter((mortgage) => mortgage.universalLoanId !== universalLoanId);
      state.openMortgages = state.openMortgages.filter((mortgage) => mortgage.universalLoanId !== universalLoanId);
    });

    builder.addCase(transferMortgage.fulfilled, (state, { payload }) => {
      if (!Array.isArray(payload.data)) return;
      state.openMortgages = state.openMortgages.filter((mortgage) => {
        if (mortgage.internalId === payload.data.internalId) state.closedMortgages.push(mortgage);
        return mortgage.internalId !== payload.data.internalId;
      });
    });

    builder.addCase(deactivateMortgage.fulfilled, (state, { payload, meta }) => {
      if (!payload.data.isSuccess) return;
      state.openMortgages = state.openMortgages.filter((mortgage) => {
        if (mortgage.internalId === meta.arg.internalId) state.closedMortgages.push(mortgage);
        return mortgage.internalId !== meta.arg.internalId;
      });
    });
  }
});

export const {
  resetDeactivated,
  addDeactivatedMortgagesToQueue,
  removeDeactivatedMortgageFromQueue,
  addFailedDeactivate,
  setSoldMortgages,
  setOpenMortgages,
  setPendingMortgages,
  setDraftMortgages,
  setDeactivatedMortgages,
  setMortgageHistory,
  appendToSoldMortgages,
  appendToDeactivatedMortgages
} = mortgageSlice.actions;
export default mortgageSlice.reducer;
