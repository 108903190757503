import { IMortgageFlat, MortgageStatus } from 'shared/models/Mortgage';
import useGetMortgageByIdQuery from './useGetMortgageByIdQuery';
import { useEffect } from 'react';
import { useAppDispatch } from 'state-management/hooks';
import { appendToDeactivatedMortgages, appendToSoldMortgages } from '../mortgage.slice';

const useFetchOneMortgage = (mortgageId: IMortgageFlat['internalId']) => {
  const dispatch = useAppDispatch();

  const { data, refetch, isLoading } = useGetMortgageByIdQuery(mortgageId);

  useEffect(() => {
    if (data?.status === MortgageStatus.Sold) {
      dispatch(appendToSoldMortgages({ data }));
    }
    if (data?.status === MortgageStatus.Deactivated) {
      dispatch(appendToDeactivatedMortgages({ data }));
    }
  }, [data, dispatch]);

  return { refetch, isLoading };
};

export default useFetchOneMortgage;
