import { RootState } from '../../store';
import { createSelector } from '@reduxjs/toolkit';

export const selectBidTapes = (state: RootState) => state.bidTape.bidTapes;
export const selectActiveBidTapes = (state: RootState) => state.bidTape.bidTapes.filter((bidTape) => bidTape.isActive);

export const selectSelectedBidTapeId = (state: RootState) => state.bidTape.selectedBidTapeId;

export const selectSelectedBidTape = createSelector(selectBidTapes, selectSelectedBidTapeId, (bidTapes, bidTapeId) =>
  bidTapes.find((bidTape) => bidTape.id === bidTapeId)
);
