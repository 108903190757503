import { useQuery } from '@tanstack/react-query';
import { MortgageRepository } from 'shared/api/repositories/MortgageRepository/MortgageRepository';
import { IMortgageFlat } from 'shared/models/Mortgage';

const mortgageRepository = new MortgageRepository();

const useGetMortgageByIdQuery = (mortgageId: IMortgageFlat['internalId']) =>
  useQuery({
    enabled: false,
    queryKey: ['mortgages', mortgageId],
    queryFn: ({ queryKey }) => mortgageRepository.getOne(queryKey[1]),
    select: (response) => response.data
  });

export default useGetMortgageByIdQuery;
